import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ContentBlocks from '../components/contentBlocks'
import Helmet from 'react-helmet'

const Page = ({ data }) => {
  const {
    page,
    page: { _rawContentBlocks: pageBlocks, _rawHero: hero }
  } = data

  const seo = {
    title: page.title,
    seoDescription: page.seoDescription,
    slug: page.slug
  }
  return (
    <Layout seo={seo}>
      <Helmet>
        <title>{page.title}</title>
        <link
          href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,500,600,700,900'
          rel='stylesheet'
        />
      </Helmet>
      <ContentBlocks pageBlocks={pageBlocks} />
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($slug: String) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      seoDescription
      slug {
        current
      }
      _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`
export default Page
